import { Box, Typography } from '@mui/material'
import { AvailableProduct } from 'components/products/product'
import { useTranslation } from 'react-i18next'
import { ExpandDetailsContainer } from './shared'

type ProductSecondaryInfoProps = {
    product: AvailableProduct
}

export const ProductSecondaryInfo: React.FC<ProductSecondaryInfoProps> = ({ product }) => {
    const { t } = useTranslation()

    return (
        <ExpandDetailsContainer>
            <Typography variant="body2">{t('DELIVERED_BY', 'Levert av')}</Typography>
            <Typography variant="body2">
                {product.vendorName} {product.tenantName ? `(${product.tenantName})` : null}
            </Typography>

            <Box mt={1.25}>
                <Typography variant="body2">{t('TEXT_DESCRIPTION', 'Beskrivelse')}</Typography>
                <Typography variant="body2">{product.description ?? '-'}</Typography>
            </Box>

            {product.disclaimer && (
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1.25}>
                    <Typography variant="body2">
                        {`${t('TEXT_DISCLAIMER', 'Ansvarsfraskrivelse:')} ${product.disclaimer}`}
                    </Typography>
                </Box>
            )}
        </ExpandDetailsContainer>
    )
}
