/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import {
    ValidatorInBetweenValue,
    ValidatorMaxLength,
    ValidatorRequired,
    composeValidators,
} from '../../utils/validators/validator-required'
import { CubitSelectAdapter } from '../general/forms/cubit-select-adapter'
import { CubitTextFieldAdapter } from '../general/forms/cubit-text-field-adapter'
import { Tenant } from './models/tenant'
import { Vendor, VendorType } from './models/vendor'
import { CubitPriceType, getTotalProductPrice, Product } from './product'
import { SelectOption } from '../../types/util'

type Props = {
    id: string
    initialValues?: any
    onSubmit: any
    tenants: Tenant[]
    vendors: Vendor[]
    productTypeOptions: SelectOption<string>[]
}
const styles = {
    innerForm: css`
        padding: 10px 0;
    `,
    inlineInputs: css`
        display: flex;
        width: 50%;
    `,
}

export const ProductForm: React.FC<Props> = ({ id, initialValues, onSubmit, tenants, vendors, productTypeOptions }) => {
    const { t } = useTranslation()
    const parse = (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value))

    const tenantsOptions = tenants.map((x) => ({ value: x.id, label: x.name }))
    const vendorsOptions = vendors.map((x) => ({ value: x.id, label: x.name }))
    const cubitPriceTypes = [
        { label: t('TEXT_FLAT', 'Fast'), value: CubitPriceType.Flat },
        { label: t('TEXT_PERCENTAGE', 'Prosent'), value: CubitPriceType.Percentage },
    ]

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {
                const isCubitVendor = vendors.find((v) => v.id === values.vendorId)?.type === VendorType.Cubit
                return (
                    <form id={id} onSubmit={handleSubmit}>
                        <div css={styles.innerForm}>
                            <Field
                                name="name"
                                width="75%"
                                component={CubitTextFieldAdapter}
                                label={t('TEXT_NAME', 'Navn')}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                variant="standard"
                            />
                            <Field
                                name="description"
                                width="75%"
                                component={CubitTextFieldAdapter}
                                label={t('TEXT_DESCRIPTION', 'Beskrivelse')}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                variant="standard"
                            />
                            <Field
                                name="vendorId"
                                width="75%"
                                component={CubitSelectAdapter}
                                valueIsObject={false}
                                options={vendorsOptions}
                                label={t('TEXT_PRODUCT_VENDOR', 'Produktleverandør')}
                                validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                variant="standard"
                            />
                            {isCubitVendor && (
                                <Field
                                    name="tenantId"
                                    width="75%"
                                    component={CubitSelectAdapter}
                                    valueIsObject={false}
                                    options={tenantsOptions}
                                    label={t('TEXT_PRODUCT_TENANT', 'Tenant')}
                                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                    variant="standard"
                                />
                            )}
                            {isCubitVendor && (
                                <Field
                                    name="type"
                                    width="75%"
                                    component={CubitSelectAdapter}
                                    valueIsObject={false}
                                    options={productTypeOptions}
                                    label={t('TEXT_PRODUCT_TYPE', 'Produkttype')}
                                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                    variant="standard"
                                />
                            )}
                            {isCubitVendor && (
                                <Field
                                    name="disclaimer"
                                    width="75%"
                                    component={CubitTextFieldAdapter}
                                    label={t('TEXT_PRODUCT_DISCLAIMER', 'Ansvarsfraskrivelse')}
                                    validate={composeValidators(
                                        (value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required')), // Use your custom validators with composeValidators
                                        (value) =>
                                            ValidatorMaxLength(
                                                value,
                                                1000,
                                                t('TEXT_MAX_LENGTH', 'Må maksimalt inneholde {{maxLength}} tegn', {
                                                    maxLength: 1000,
                                                }),
                                            ),
                                    )}
                                    variant="outlined"
                                    rows={5}
                                    multiline
                                />
                            )}
                            <Field
                                name="basePrice"
                                width="50%"
                                parse={parse}
                                component={CubitTextFieldAdapter}
                                type={'number'}
                                label={t('TEXT_BASE_PRICE', 'Pris før påslag')}
                                validate={(value) =>
                                    ValidatorInBetweenValue(
                                        value,
                                        0,
                                        100000,
                                        t('TEXT_MIN_MAX_VALUE', `Min value is 0 and Max value is 100000`, {
                                            min: 0,
                                            max: 100000,
                                        }),
                                    )
                                }
                                variant="standard"
                            />
                            <Field
                                name="vat"
                                parse={parse}
                                width="50%"
                                component={CubitTextFieldAdapter}
                                type={'number'}
                                disabled
                                label={t('TEXT_VAT', 'MVA')}
                                initialValue={25}
                                validate={(value) =>
                                    ValidatorInBetweenValue(
                                        value,
                                        0,
                                        100,
                                        t('TEXT_MIN_MAX_VALUE', `Min value is 0 and Max value is 100`, {
                                            min: 0,
                                            max: 100,
                                        }),
                                    )
                                }
                                variant="standard"
                            />
                            <div css={styles.inlineInputs}>
                                <Field
                                    name="cubitPrice"
                                    width="90%"
                                    component={CubitTextFieldAdapter}
                                    label={t('TEXT_CUBIT_CUT', 'Cubit påslag')}
                                    parse={parse}
                                    type={'number'}
                                    validate={(value) =>
                                        ValidatorInBetweenValue(
                                            value,
                                            0,
                                            100000,
                                            t('TEXT_MIN_MAX_VALUE', `Min value is 0 and Max value is 100000`, {
                                                min: 0,
                                                max: 100000,
                                            }),
                                        )
                                    }
                                    variant="standard"
                                />
                                <Field
                                    name="cubitPriceType"
                                    width="100%"
                                    component={CubitSelectAdapter}
                                    valueIsObject={false}
                                    options={cubitPriceTypes}
                                    label={t('TEXT_PERCENT_NOK', '% / NOK')}
                                    validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                    variant="standard"
                                />
                            </div>
                            <div>{t('TEXT_FINAL_PRICE', 'Pris til kunden')}</div>
                            <p>{getTotalProductPrice(values as Product)}</p>
                        </div>
                    </form>
                )
            }}
        />
    )
}
