/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Add } from '@mui/icons-material'
import { Dialog, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMobileMediaQuery } from '../../app/material-ui/material-ui.config'
import { dispatchSubmit } from '../../utils/forms-utils'
import { QueryStatusView } from '../general/query-status.view'
import { useCreateCompanyMutation } from './companies.service'
import { CompanyForm } from './company-form'
import { CompanyCreateRequest } from './models/company'
import StyledContainedButton from '../styled/styled-contained-button'

export const ADD_NEW_COMPANY_FORM_ID = 'ADD_NEW_COMPANY_FORM_ID'

export const AddCompany: React.FC = () => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [create, { isLoading, error, isError }] = useCreateCompanyMutation()
    const onSubmit = (values: CompanyCreateRequest) => {
        if (!isLoading) {
            create(values)
                .unwrap()
                .then(() => setOpen(false))
        }
    }
    const styles = {
        addButton: css`
            box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
            @media ${getMobileMediaQuery()} {
                margin-left: 16px;
            }
        `,
    }
    return (
        <>
            <StyledContainedButton
                disableElevation
                css={styles.addButton}
                startIcon={<Add />}
                color={'secondary'}
                variant={'contained'}
                onClick={() => setOpen(true)}
            >
                {t('TEXT_ADD_COMPANY', 'Legg til nytt meglerkontor')}
            </StyledContainedButton>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>{t('TEXT_ADD_COMPANY_TITLE', 'Legg til nytt meglerkontor')}</DialogTitle>
                <DialogContent>
                    <CompanyForm id={ADD_NEW_COMPANY_FORM_ID} onSubmit={onSubmit} />
                    <QueryStatusView error={error} isError={isError} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        disabled={isLoading}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(ADD_NEW_COMPANY_FORM_ID)}
                    >
                        {t('TEXT_SAVE', 'LAGRE')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
