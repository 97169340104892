import { getStandardRightActions, TopBar } from '../../topbar/top-bar'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { BottomPageNavigation } from '../../bottom-navigation/bottom-navigation'
import { KotiContainer } from '../../general/layout/koti-container'
import { Grid } from '@mui/material'
import { InePropertyProduct } from '../InePropertyProduct'
import { useGetProductsByCadastreNumberQuery } from '../../products/products.service'
import { Loader } from '../../general/loader/loader'
import { HistoricPropertyTop } from './historic-property-top'
import { PropertyUnitAddress } from '../../search/models/property-search-response'

export const HistoricPropertyView: React.FC = () => {
    const [searchParams] = useSearchParams()
    const cadastreNumber = searchParams.get('cadastreNumber') || ''
    const { data } = useGetProductsByCadastreNumberQuery({ cadastreNumber: cadastreNumber })

    if (!data) return <Loader />

    const agreement = data.ineData[cadastreNumber][0]
    const address: PropertyUnitAddress = {
        addressText: agreement.address || null,
        postalCode: agreement.postalCode || null,
        postalCodeName: agreement.city || null,
        // TODO dont know what an agreement here is, should the residential number always be null here?
        residentialNumber: null,
    }
    return (
        <div>
            <TopBar rightActions={getStandardRightActions()} />
            <KotiContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HistoricPropertyTop cadastreNumber={cadastreNumber} address={address} />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        {cadastreNumber && (
                            <InePropertyProduct
                                cadastreNumber={cadastreNumber}
                                address={address}
                                owners={[agreement.ownerContact.name]}
                            />
                        )}
                    </Grid>
                </Grid>
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
