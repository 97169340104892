/** @jsxImportSource @emotion/react */
import { Edit } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dispatchSubmit } from '../../utils/forms-utils'
import { useUpdateCompanyUserMutation } from '../companies/companies.service'
import { User } from './user'
import { UserForm } from './user-form'
import StyledContainedButton from '../styled/styled-contained-button'

type Props = {
    user: User
    companyId: string
}

export const EDIT_USER_FORM = 'EDIT_USER_FORM'
export const EditUser: React.FC<Props> = ({ user, companyId }) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)

    const [update, { isLoading }] = useUpdateCompanyUserMutation()
    const onSubmit = (values: any) => {
        if (!isLoading) {
            update({
                userId: user.id,
                companyId: companyId,
                ...values,
            })
                .unwrap()
                .then(() => setOpen(false))
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Edit aria-label={t('TEXT_EDIT_USER_TITLE', 'Rediger bruker')} />
            </IconButton>
            <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>{t('TEXT_EDIT_USER_TITLE', 'Rediger bruker')}</DialogTitle>
                <DialogContent>
                    <UserForm id={EDIT_USER_FORM} onSubmit={onSubmit} initialValues={user} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('TEXT_CANCEL', 'AVBRYT')}</Button>
                    <StyledContainedButton
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => dispatchSubmit(EDIT_USER_FORM)}
                    >
                        {t('TEXT_SAVE', 'Lagre')}
                    </StyledContainedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
