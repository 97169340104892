import React from 'react'
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { CartProperty, PropertyUnitAddress } from 'components/search/models/property-search-response'
import { formatCadastreNumber } from 'utils/cadastre-utils'
import { MultiStringViewer } from 'components/general/multi-viewer/multi-string-viewer'
import { useGetProductsByCadastreNumberQuery } from 'components/products/products.service'
import { ProductDisplay } from 'components/property/products/ProductDisplay'
import { useGetCartQuery } from 'components/cart/cart.service'
import { isIneProduct } from 'components/products/product-helper'
import { QueryStatusView } from 'components/general/query-status.view'
import { OsloTaxesProductNoData } from 'components/property/products/oslo-taxes-product-no-data'

const OSLO_TAXES_PROD_OVVERIDE_CADASTRE_ID = 0

const HousingUnitInfoDisplay: React.FC<{ property: CartProperty }> = ({ property }) => {
    const house = property.units[0]
    return (
        <>
            <Box display="flex" justifyContent="space-between" px={7}>
                <Typography variant="body2">{formatCadastreNumber(property.cadastreNumber)}</Typography>
                <Box sx={{ fontSize: '0.875rem' }}>
                    <MultiStringViewer owners items={property.owners} />
                </Box>

                <Typography variant="body2">{house.address.addressText}</Typography>
                <Typography variant="body2">{`${house.address.postalCode || ''} ${
                    property.address.postalCodeName || ''
                }`}</Typography>
                <Typography variant="body2">{house.buildingCodeName}</Typography>
                <Typography variant="body2">{house.address.residentialNumber}</Typography>
            </Box>
            {/* mx must match dialogcontent padding to take full width */}
            <Divider sx={{ pt: 2.5, mx: -3 }} />
        </>
    )
}

type HousingHousingCommunityUnitProductsDialogProps = {
    isOpen: boolean
    onClose: () => void
    property: CartProperty | null
    mainPropertyMetaData: {
        owners: string[]
        cadastreNumber: string
        address: PropertyUnitAddress
    }
}

export const HousingCommunityUnitProductsDialog: React.FC<HousingHousingCommunityUnitProductsDialogProps> = ({
    isOpen,
    onClose,
    property,
    mainPropertyMetaData,
}) => {
    const { t } = useTranslation()

    const {
        data: propertyProducts,
        isError: isErrorProducts,
        error: productsError,
        isFetching: isLoadingProducts,
        isSuccess: isSuccessProducts,
    } = useGetProductsByCadastreNumberQuery(
        {
            cadastreNumber: property?.cadastreNumber || '',
            cadastreId: property?.units[0]?.cadastreId,
        },
        { skip: !isOpen },
    )
    const {
        data: cart,
        isLoading: isLoadingCart,
        isSuccess: isSuccessCart,
        isError: isErrorCart,
        error: cartError,
    } = useGetCartQuery()

    const isLoading = isLoadingCart || isLoadingProducts

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
            <CloseWrapper>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </CloseWrapper>

            <DialogTitle>{t('PROPERTY_PRODUCTS_DIALOG_TITLE', 'Produkter')}</DialogTitle>

            <DialogContent sx={{ mt: 2 }}>
                {property && <HousingUnitInfoDisplay property={property} />}
                <ProductsContainer>
                    <LoadingContainer>{isLoading && <CircularProgress />}</LoadingContainer>
                    {propertyProducts?.products.length === 0 && isSuccessProducts && (
                        <div>
                            <Typography variant="h6" textAlign="center">
                                {t('PROPERTY_PRODUCTS_DIALOG_EMPTY', 'No products found')}
                            </Typography>
                        </div>
                    )}
                    {!isLoadingProducts && propertyProducts?.ineFetchError === true && <OsloTaxesProductNoData />}
                    {isSuccessProducts &&
                        isSuccessCart &&
                        property &&
                        !isLoadingProducts &&
                        propertyProducts.products.length !== 0 &&
                        propertyProducts.products.map((product) => {
                            // need to do this check as it behaves differently for housing communities and should be added for all
                            // listed units in the community thats why we ovveride it with the "main" property of housing community
                            const isIne = isIneProduct(product)

                            return (
                                <ProductDisplay
                                    key={product.id}
                                    product={product}
                                    cart={cart}
                                    property={
                                        isIne
                                            ? {
                                                  cadastreId: OSLO_TAXES_PROD_OVVERIDE_CADASTRE_ID,
                                                  address: mainPropertyMetaData.address,
                                                  cadastreNumber: mainPropertyMetaData.cadastreNumber,
                                                  owners: mainPropertyMetaData.owners,
                                                  units: [],
                                              }
                                            : property
                                    }
                                />
                            )
                        })}
                    <QueryStatusView error={cartError || productsError} isError={isErrorCart || isErrorProducts} />
                </ProductsContainer>
            </DialogContent>
        </Dialog>
    )
}

const CloseWrapper = styled('div')({
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 1,
})

const LoadingContainer = styled('div')({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
})

const ProductsContainer = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '300px',
    position: 'relative',

    '& .product-container': {
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid #bebebe`,
    },

    '& .product-container:last-of-type': {
        borderBottom: 'none',
    },
}))
