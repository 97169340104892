import React from 'react'
import { CompanyConfig } from '../companies/models/company'
import { Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SectionFormDisplay } from './config/SectionFormDisplay'
import { EditCompanyConfigDialog } from './config/EditCompanyConfigDialog'

type Section = {
    title: string
    key: keyof CompanyConfig
}

// t('TEXT_COMPANY_CONFIG_PERMISSIONS_TITLE')
const SECTIONS: Section[] = [{ title: 'TEXT_COMPANY_CONFIG_PERMISSIONS_TITLE', key: 'permissions' }]

type CompanyAdminConfigViewProps = {
    companyConfig: CompanyConfig
    companyId: string
}

export const CompanyAdminConfigView: React.FC<CompanyAdminConfigViewProps> = ({ companyConfig, companyId }) => {
    const { t } = useTranslation()

    const [selectedSectionKey, setSelectedSectionKey] = React.useState<keyof CompanyConfig | null>(null)

    return (
        <>
            <div>
                {SECTIONS.map(({ key, title }) => (
                    <Section key={key}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            {t(title)}
                        </Typography>

                        <SectionFormDisplay form={companyConfig[key]} onEnableEdit={() => setSelectedSectionKey(key)} />
                    </Section>
                ))}
            </div>
            {selectedSectionKey && (
                <EditCompanyConfigDialog
                    onClose={() => setSelectedSectionKey(null)}
                    configSectionKey={selectedSectionKey}
                    config={companyConfig}
                    companyId={companyId}
                />
            )}
        </>
    )
}

const Section = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
}))
