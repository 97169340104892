import React from 'react'
import { Card, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CartProperty } from 'components/search/models/property-search-response'
import { useGetProductsByCadastreNumberQuery } from 'components/products/products.service'
import { ProductDisplay } from 'components/property/products/ProductDisplay'
import { useGetCartQuery } from 'components/cart/cart.service'
import { Loader } from 'components/general/loader/loader'
import { QueryStatusView } from 'components/general/query-status.view'
import { OsloTaxesProductNoData } from './oslo-taxes-product-no-data'

type HousingPropertyProductsListProps = {
    property: CartProperty
}

// used for these property types:
// main property, leased property, children of leased sectioned and sectioned properties
export const PropertyProductsList: React.FC<HousingPropertyProductsListProps> = ({ property }) => {
    const { t } = useTranslation()

    const {
        data: propertyProducts,
        isError: isErrorProducts,
        error: productsError,
        isLoading: isLoadingProducts,
        isSuccess: isSuccessProducts,
    } = useGetProductsByCadastreNumberQuery({
        cadastreNumber: property?.cadastreNumber || '',
    })
    const {
        data: cart,
        isLoading: isLoadingCart,
        isSuccess: isSuccessCart,
        isError: isErrorCart,
        error: cartError,
    } = useGetCartQuery()

    const isLoading = isLoadingCart || isLoadingProducts

    return (
        <ProductsContainer>
            <LoadingContainer>{isLoading && <Loader />}</LoadingContainer>
            {propertyProducts?.products.length === 0 && (
                <div>
                    <Typography variant="h6" textAlign="center">
                        {t('PROPERTY_PRODUCTS_DIALOG_EMPTY', 'No products found')}
                    </Typography>
                </div>
            )}
            {!isLoading && propertyProducts?.ineFetchError === true && <OsloTaxesProductNoData />}

            {isSuccessProducts &&
                isSuccessCart &&
                propertyProducts.products.length !== 0 &&
                propertyProducts.products.map((product) => {
                    return (
                        <Card key={product.id} elevation={0} sx={{ mb: '2px' }}>
                            <ProductDisplay product={product} cart={cart} property={property} />
                        </Card>
                    )
                })}
            <QueryStatusView error={cartError || productsError} isError={isErrorCart || isErrorProducts} />
        </ProductsContainer>
    )
}

const LoadingContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const ProductsContainer = styled('div')(({ theme }) => ({
    position: 'relative',

    '& .product-container': {
        paddingBottom: theme.spacing(2),
    },
}))
