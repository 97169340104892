import {
    PropertyResponseItem,
    TransformedPropertyResponseItemResponse,
} from '../../components/search/models/property-search-response'
import { isMainProperty, isPropertyLeased, isPropertySectioned2 } from '../cadastre-utils'

export const transformPropertyUnitsRequest = (
    initialRequest: PropertyResponseItem[],
): TransformedPropertyResponseItemResponse => {
    if (initialRequest.length === 1) {
        const firstProperty = initialRequest[0]
        const cadastreParts = firstProperty.cadastreNumber.split('/')
        const isLeasedProperty = cadastreParts[2] !== '0'
        const isSectionedProperty = cadastreParts[3] !== '0'
        if (firstProperty?.isCooperative) return { properties: initialRequest, type: 'HousingCommunity' }
        if (isMainProperty(firstProperty.cadastreNumber) && !isLeasedProperty && !isSectionedProperty)
            return { properties: initialRequest, type: 'MainProperty' }

        if (isLeasedProperty && !isSectionedProperty) return { properties: initialRequest, type: 'LeasedProperty' }

        if (!isLeasedProperty && isSectionedProperty) return { properties: initialRequest, type: 'SectionedProperty' }
        if (isLeasedProperty && isSectionedProperty)
            return { properties: initialRequest, type: 'LeasedSectionedProperty' }
    }

    const isSectioned = isPropertySectioned2(initialRequest)
    const isLeasedProperty = isPropertyLeased(initialRequest)

    if (!isLeasedProperty && isSectioned) return { properties: initialRequest, type: 'SectionedProperty' }
    if (isLeasedProperty && !isSectioned) return { properties: initialRequest, type: 'LeasedProperty' }
    else if (isLeasedProperty && isSectioned) return { properties: initialRequest, type: 'LeasedSectionedProperty' }

    throw new Error('Unaxpected property type case')
}
