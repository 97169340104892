import React from 'react'
import { Box, Grid } from '@mui/material'
import { PropertyOverview } from 'components/property/shared/PropertyOverview'
import {
    flattenAndMapHouseUnits,
    generatePropertyAddressObject,
    getAllOwners,
    getMainPropertyCadastreNumber,
} from 'utils/cadastre-utils'
import { CartProperty, PropertyResponseItem } from 'components/search/models/property-search-response'
import { HousingCommunityUnitTable } from './HousingCommunityUnitTable'
import { useTranslation } from 'react-i18next'
import { HousingCommunityUnitProductsDialog } from './HousingCommunityUnitProductsDialog'

type HousingCommunityPropertyViewProps = {
    units: PropertyResponseItem[]
}

// unit[0] is the parent property that holds all other housing units related to this property
export const HousingCommunityPropertyView: React.FC<HousingCommunityPropertyViewProps> = ({ units }) => {
    const { t } = useTranslation()

    const [selectedProperty, setSelectedProperty] = React.useState<null | CartProperty>(null)

    const mainProperty = units?.[0] || undefined

    const sanitizedMainPropertyCadastreNr = getMainPropertyCadastreNumber(mainProperty?.cadastreNumber || null)
    const mainPropertyAddress = generatePropertyAddressObject([mainProperty])

    // moved dialog up the top so need to memoize this in order to prevent PropertyOverview from rerendering
    const owners = React.useMemo(() => getAllOwners(units), [units])
    const allHouseUnits = React.useMemo(() => flattenAndMapHouseUnits(units), [units])

    const handleOpenProductDialog = React.useCallback((property: CartProperty) => {
        setSelectedProperty(property)
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <PropertyOverview
                    data={units}
                    isPropertySectioned={false}
                    sanitizedMainPropertyCadastreNr={sanitizedMainPropertyCadastreNr}
                    mainProperty={mainProperty}
                    allOwners={owners}
                />
                <Box mt={2}>
                    <HousingCommunityUnitTable
                        units={allHouseUnits}
                        title={t('HOUSING_UNITS', 'Bruksenheter ({{huCount}})', {
                            huCount: allHouseUnits.length,
                        })}
                        onOpenProductDialog={handleOpenProductDialog}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}></Grid>

            <HousingCommunityUnitProductsDialog
                isOpen={Boolean(selectedProperty)}
                onClose={() => setSelectedProperty(null)}
                property={selectedProperty}
                mainPropertyMetaData={{
                    address: mainPropertyAddress,
                    cadastreNumber: sanitizedMainPropertyCadastreNr,
                    owners: owners,
                }}
            />
        </>
    )
}
