import React from 'react'
import { PropertyResponseItem, PropertyType } from 'components/search/models/property-search-response'
import { getMainProperty, isSectionedPropertyParent } from 'utils/cadastre-utils'
import { SectionedPropertyParentView } from './SectionedPropertyParentView'
import { SectionedPropertyChildView } from './SectionedPropertyChildView'

type SectionedPropertyViewProps = {
    units: PropertyResponseItem[]
    type: PropertyType
}

export const SectionedPropertyView: React.FC<SectionedPropertyViewProps> = ({ units, type }) => {
    const mainProperty = getMainProperty(units) || units[0]
    const isParent = isSectionedPropertyParent(mainProperty.cadastreNumber)

    if (isParent) return <SectionedPropertyParentView units={units} type={type} />

    return <SectionedPropertyChildView units={units} propertyType={type} />
}
