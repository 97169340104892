/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, FormControl, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'
import { useTranslation } from 'react-i18next'
import { CubitCompany } from '../../companies/models/cubit-company.type'
import { useLazySearchCompaniesQuery } from '../../../redux/companies-api'
import { formatOrgNumber, getPostalCode } from '../../companies/companies-helper'
import { Close, Search } from '@mui/icons-material'
import { css } from '@emotion/react'

const styles = {
    company2ndLine: css`
        display: flex;
    `,
    orgNr: css`
        margin-right: 40px;
    `,
    selectedCompany: css`
        padding: 10px 0;
        white-space: nowrap;
        margin-bottom: 30px;
        display: inline-flex;
        align-items: center;
    `,
}
const Block: React.FC<any> = ({ label, value }) => {
    const styles = {
        label: css`
            font-size: 12px;
        `,
        block: css`
            margin-right: 35px;
        `,
    }
    return (
        <div css={styles.block}>
            <Typography css={styles.label}>{label}</Typography>
            <Typography>{value}</Typography>
        </div>
    )
}
const CubitCompanySearchAdapter: React.FC<any> = (props: any) => {
    const [selectedCompany, setSelectedCompany] = useState<any>()
    const [searchValue, setSearchValue] = useState('')
    const [trigger, { data }] = useLazySearchCompaniesQuery()
    useEffect(() => {
        if (searchValue && searchValue.length > 1) {
            trigger(searchValue)
        }
    }, [trigger, searchValue])
    const {
        label,
        valueChanged,
        input: { onChange },
        meta: { touched, error },
    } = props
    const { t } = useTranslation()
    return (
        <>
            <FormControl css={cubitFormStyles.field}>
                <Autocomplete
                    onChange={(event, value) => {
                        setSelectedCompany(value)
                        onChange(value)
                        if (valueChanged) {
                            valueChanged(value)
                        }
                    }}
                    id="company-search"
                    options={data ?? []}
                    autoHighlight
                    noOptionsText={t('TEXT_NO_OPTIONS', 'Ingen treff')}
                    forcePopupIcon={false}
                    getOptionLabel={(option: CubitCompany) => option.name}
                    filterOptions={(options: CubitCompany[]) => {
                        return options.filter((o) => {
                            return (
                                o.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                o.organizationNumber.toString().includes(searchValue.replaceAll(' ', ''))
                            )
                        })
                    }}
                    renderOption={(props, option: CubitCompany) => (
                        <Box component="li" {...props} key={`${option.organizationNumber}_${option.name}`}>
                            <div>
                                <div>{option.name}</div>
                                <div css={styles.company2ndLine}>
                                    <div css={styles.orgNr}>
                                        {formatOrgNumber(option.organizationNumber.toString())}
                                    </div>
                                    <div>{option.companyType}</div>
                                </div>
                            </div>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color={'primary'}
                            variant={'standard'}
                            label={label}
                            onChange={(event) => setSearchValue(event.target.value)}
                            error={!!(touched && error)}
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search color="primary" />
                                    </InputAdornment>
                                ),
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </FormControl>

            {selectedCompany && (
                <div>
                    <div css={styles.selectedCompany}>
                        <Block label={t('TEXT_NAME', 'Navn')} value={selectedCompany.name} />
                        <Block
                            label={t('TEXT_ORG_NR', 'Org.nummer')}
                            value={formatOrgNumber(selectedCompany.organizationNumber.toString())}
                        />
                        <Block label={t('TEXT_POStAL_CODE', 'Postnummer')} value={getPostalCode(selectedCompany)} />
                        <Block
                            label={t('TEXT_BUSINESS_CODE_COMPANY_TYPE', 'Virksomhetstype')}
                            value={selectedCompany.companyType}
                        />
                        <IconButton onClick={() => setSelectedCompany(null)}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
            )}
        </>
    )
}
export { CubitCompanySearchAdapter }
