import { useGetCompanyQuery } from '../../companies/companies.service'
import { CompanyInfo } from '../../companies/single-page/company-info'
import { CompanyEdit } from './company-edit'
import { Paper } from '@mui/material'

type Props = {
    id: string
}

export const CompanySettings: React.FC<Props> = ({ id }) => {
    const { data } = useGetCompanyQuery(id)
    if (!data) return null
    return (
        <Paper elevation={0} square={true}>
            <CompanyEdit company={data} />
            <CompanyInfo company={data} />
        </Paper>
    )
}
