/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { PropertyResponseItem } from 'components/search/models/property-search-response'
import {
    formatCadastreNumber,
    generatePropertyAddressObject,
    getAllOwners,
    isMainProperty,
    normalizeCadastreNumber,
} from 'utils/cadastre-utils'
import { PropertyHeader, propertyStyles } from 'components/property/property-helper'
import { MultiStringViewer } from 'components/general/multi-viewer/multi-string-viewer'
import { ProductsView } from 'components/cadastre/products-view'

type Props = {
    units: PropertyResponseItem[]
    title: string
}

export const UnitsTable: React.FC<Props> = ({ units, title }) => {
    const [searchParams] = useSearchParams()
    const cadastreNumber = searchParams.get('cadastreNumber')
    const index = units.findIndex((u) => u.cadastreNumber === cadastreNumber && !isMainProperty(cadastreNumber))
    const [opened, setOpened] = useState<string[]>(index > -1 ? [`${cadastreNumber}${index}`] : [])
    const styles = propertyStyles

    const handleOpened = (cadastreId: string) => {
        if (opened.includes(cadastreId)) {
            setOpened(opened.filter((o) => o !== cadastreId))
        } else {
            setOpened([...opened, cadastreId])
        }
    }

    useEffect(() => {
        if (cadastreNumber && !isMainProperty(cadastreNumber)) {
            setTimeout(() => {
                const element = document.getElementById(normalizeCadastreNumber(cadastreNumber))
                element?.scrollIntoView({ behavior: 'smooth' })
            }, 0)
        }
    }, [cadastreNumber])
    return (
        <div>
            <div css={styles.sectionTitle}>{title}</div>
            <div>
                <PropertyHeader />
                {units.map((unit, index) => {
                    const hu = unit.housingUnits[0] ?? { address: {} }
                    const owners = getAllOwners([unit])
                    return (
                        <div
                            key={index}
                            css={
                                opened.includes(unit.cadastreNumber + index)
                                    ? styles.expandedProperty
                                    : styles.contractedProperty
                            }
                        >
                            <div
                                css={[styles.propertyLine, styles.propertyLineHeader]}
                                tabIndex={0}
                                onKeyDown={(event) =>
                                    event.key === 'Enter' && handleOpened(unit.cadastreNumber + index)
                                }
                                onClick={() => handleOpened(unit.cadastreNumber + index)}
                            >
                                <div css={[styles.column1, styles.gridItem]}>
                                    {formatCadastreNumber(unit.cadastreNumber)}
                                </div>
                                <div css={[styles.column2, styles.gridItem]}>
                                    <MultiStringViewer owners items={owners} />
                                </div>
                                <div css={[styles.column3, styles.gridItem]}>{hu.address.addressText}</div>
                                <div css={[styles.column4, styles.gridItem]}>{`${hu.address.postalCode || ''} ${
                                    hu.address.postalCodeName || ''
                                }`}</div>
                                <div css={[styles.column5, styles.gridItem]}>{hu.buildingCodeName}</div>
                                <div css={[styles.column6, styles.gridItem]}>{hu.address.residentialNumber}</div>
                                <div css={[styles.column7, styles.gridItem]}>
                                    <KeyboardArrowDown />
                                </div>
                            </div>
                            {opened.includes(unit.cadastreNumber + index) && (
                                <div css={styles.expandedContent}>
                                    <ProductsView
                                        address={generatePropertyAddressObject([unit])}
                                        cadastreNumber={unit.cadastreNumber}
                                        owners={owners}
                                        unit={{
                                            cadastreId: hu.cadastreId,
                                            cadastreNumber: unit.cadastreNumber,
                                            residentialNumber: hu.address.residentialNumber,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
