/** @jsxImportSource @emotion/react */
import { Company } from '../models/company'
import { InfoBlock } from '../../general/info-block/info-block'
import { useTranslation } from 'react-i18next'
import { InfoLine } from '../../general/info-block/info-line'
import { css } from '@emotion/react'
import { formatOrgNumber } from '../companies-helper'
import { useGetCompanyQuery } from '../companies.service'

type Props = {
    company: Company
}
type CompanyBlockProps = {
    company: Company
    title: string
}
const CompanyBlock: React.FC<CompanyBlockProps> = ({ company, title }) => {
    const { t } = useTranslation()
    return (
        <InfoBlock title={title}>
            <InfoLine label={t('TEXT_NAME', 'Navn')} value={company.name} />
            <InfoLine label={t('TEXT_ORG_NR', 'Org. nr.')} value={formatOrgNumber(company.organizationNr)} />
            {/*<InfoLine label={t('TEXT_EMAIL', 'E-postadresse')} value={company.email} />*/}
            <InfoLine label={t('TEXT_ADDRESS', 'Adresse')} value={company.address.addressText} />
            <InfoLine label={t('TEXT_POSTAL_CODE', 'Postnummer')} value={company.address.postalCode} />
        </InfoBlock>
    )
}
export const CompanyInfo: React.FC<Props> = ({ company }) => {
    const { data: parentCompany } = useGetCompanyQuery(company.parentId ?? '', {
        skip: company.parentId === null,
    })

    const { t } = useTranslation()
    const styles = {
        line: css`
            display: flex;
            align-items: center;
        `,
        checkBox: css`
            margin-right: 10px;
        `,
    }

    return (
        <div>
            {parentCompany && (
                <CompanyBlock company={parentCompany} title={t('TEXT_PARENT_INFO', 'Parent company info')} />
            )}
            <CompanyBlock company={company} title={t('TEXT_CONTACT_INFO', 'Kontaktinformasjon')} />
            {!company.parentId && (
                <InfoBlock title={t('TEXT_INVOICE_DETAILS', 'Fakturering')}>
                    <InfoLine
                        label={t('TEXT_INVOICE_INTERVAL', 'Faktureringsintervall')}
                        value={`${company.invoiceInterval} dager`}
                    />
                    <InfoLine
                        label={t('TEXT_INVOICE_ADDRESS', 'Faktureringsadresse')}
                        value={
                            <div css={styles.line}>
                                {company.useDifferentPaymentAddress ? (
                                    <div>
                                        <div>{company.invoiceAddress?.addressText}</div>
                                        <div>{company.invoiceAddress?.postalCode}</div>
                                    </div>
                                ) : (
                                    <div>
                                        <div>{company.address.addressText}</div>
                                        <div>{company.address.postalCode}</div>
                                    </div>
                                )}
                            </div>
                        }
                    />
                </InfoBlock>
            )}
        </div>
    )
}
