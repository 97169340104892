import React from 'react'
import { Add, Close } from '@mui/icons-material'
import { Box, Button, Typography, styled } from '@mui/material'
import StyledContainedButton from 'components/styled/styled-contained-button'
import { AvailableProduct } from 'components/products/product'
import { useTranslation } from 'react-i18next'

type ProductMainInfoProps = {
    product: AvailableProduct
    onCartButtonClick: () => void
    onToggleExpand: () => void
    isInCart: boolean
    isExpanded: boolean
    isCartButtonDisabled: boolean
    children?: React.ReactNode
    priceOverride?: number
}

export const ProductMainInfo: React.FC<ProductMainInfoProps> = ({
    onCartButtonClick,
    product,
    onToggleExpand,
    isExpanded,
    isInCart,
    isCartButtonDisabled,
    children,
    priceOverride,
}) => {
    const { t } = useTranslation()

    return (
        <ProductMainInfoContainer>
            <div>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    {product.name}
                </Typography>
                <MoreLessButton variant="text" onClick={onToggleExpand}>
                    {isExpanded ? t('show_less', 'Show less') : t('more_info', 'More info')}
                </MoreLessButton>
            </div>

            {children && children}

            <Box display="flex" alignItems="center">
                {priceOverride != null ? (
                    <>
                        {/* hackey way to hide the price for electricity products, if more features like this are needed
                    should use component composition instead */}
                        {priceOverride === 0 ? null : (
                            <Typography variant="body1" sx={{ mr: 3 }}>
                                {priceOverride},-
                            </Typography>
                        )}
                    </>
                ) : (
                    <Typography variant="body1" sx={{ mr: 3 }}>
                        {product.price},-
                    </Typography>
                )}

                <StyledContainedButton
                    color="secondary"
                    variant="contained"
                    startIcon={isInCart ? <Close /> : <Add />}
                    onClick={onCartButtonClick}
                    disabled={isCartButtonDisabled}
                >
                    {isInCart ? t('REMOVE_FROM_CART', 'Lagt i handlekurv') : t('ADD_TO_CART', 'Legg i handlekurv')}
                </StyledContainedButton>
            </Box>
        </ProductMainInfoContainer>
    )
}

const ProductMainInfoContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
}))

const MoreLessButton = styled(Button)(({ theme }) => ({
    textDecoration: 'underline',
    padding: 0,
    marginTop: theme.spacing(0.5),
    justifyContent: 'flex-start',
    fontSize: '0.75rem',
    color: '#000',

    '&:hover': {
        background: 'transparent',
        textDecoration: 'underline',
    },
}))
