import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './app-base-query'

export const appApi = createApi({
    tagTypes: [
        'Products',
        'History',
        'Companies',
        'CompanyUsers',
        'Profile',
        'Cart',
        'Orders',
        'AccessLog',
        'Departments',
        'Invoices',
        'Settings',
        'EventNotifications',
        'MultiChannels',
    ],
    reducerPath: 'appApi',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
})
