import dayjs, { QUnitType } from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
dayjs.extend(calendar)

export const formatDateTime = (date: any) => {
    return dayjs(date).format('DD.MM.YYYY HH:mm')
}
export const getDateObject = (date: any) => {
    return dayjs(date)
}
export const formatDate = (date: any) => {
    if (!date) return ''
    return dayjs(date).format('DD.MM.YYYY')
}
export const getDifferenceFromNow = (date: any, type: QUnitType) => {
    return dayjs(date).diff(dayjs(), type)
}
export const getCurrentDate = () => dayjs().toISOString()

export const isValidDate = (date: any) => dayjs(date).isValid()
