import { useGetProductsByCadastreNumberQuery } from '../products/products.service'
import { isIneProduct } from '../products/product-helper'
import React from 'react'
import { Loader } from '../general/loader/loader'
import { PropertyUnitAddress } from '../search/models/property-search-response'
import { OsloProduct } from '../products/single-product/oslo-product'
import { useIsOsloGuy } from '../auth/auth.slice'
import { FullWidthProduct } from '../products/single-product/full-width-product'
import { Box, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
    cadastreNumber: string
    address: PropertyUnitAddress
    owners: string[]
    propertyBuildingNumbers?: number[]
}

export const InePropertyProduct: React.FC<Props> = ({ address, cadastreNumber, owners, propertyBuildingNumbers }) => {
    const { t } = useTranslation()
    const isOsloGuy = useIsOsloGuy()

    const { data, isSuccess, isLoading } = useGetProductsByCadastreNumberQuery({
        cadastreNumber: cadastreNumber,
    })

    if (isLoading) {
        return (
            <Box my={2}>
                <Loader />
            </Box>
        )
    }

    if (!isSuccess || data?.ineFetchError) {
        return (
            <Container>
                <Typography variant="h6" textAlign="center" sx={(theme) => ({ color: theme.palette.error.light })}>
                    {t('INE_PRODUCTS_FAILED_FETCH', 'Failed to load ine product')}
                </Typography>
            </Container>
        )
    }

    const ineProduct = data.products.find((p) => isIneProduct(p))

    if (!ineProduct) return null

    return (
        <Container>
            {isOsloGuy ? (
                <OsloProduct
                    product={ineProduct}
                    ineData={data.ineData}
                    matrikkelNumber={cadastreNumber}
                    propertyBuildingNumbers={propertyBuildingNumbers}
                />
            ) : (
                <FullWidthProduct
                    expandable={false}
                    address={address}
                    matrikkelNumber={cadastreNumber}
                    product={ineProduct}
                    unitCadastreId={0}
                    owners={owners}
                />
            )}
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    background: '#FFF',
}))
