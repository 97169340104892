/** @jsxImportSource @emotion/react */

import React, { useState } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { UnitsTable } from './units-table'
import { PropertyResponseItem } from 'components/search/models/property-search-response'
import { PropertyHeader, propertyStyles } from 'components/property/property-helper'
import {
    formatCadastreNumber,
    generatePropertyAddressObject,
    getAllDistinctPropertyBuildingNumbers,
    getAllOwners,
    getDetachedHousingUnits,
    getMainPropertyCadastreNumber,
    isMainProperty,
    isPropertySectioned2,
    showDetachedHousingUnits,
} from 'utils/cadastre-utils'
import { InePropertyProduct } from 'components/property/InePropertyProduct'

type Props = {
    units: PropertyResponseItem[]
}

export const OsloUserUnitsList: React.FC<Props> = ({ units }) => {
    const [opened, setOpened] = useState<number[]>([])
    const { t } = useTranslation()
    const styles = propertyStyles
    const [searchParams] = useSearchParams()
    const nr = searchParams.get('cadastreNumber') || ''

    const cadastreNumber = getMainPropertyCadastreNumber(nr)
    const isSectioned = isPropertySectioned2(units)
    const mainProperty = generatePropertyAddressObject(units)
    const mainOwners = getAllOwners(units)
    const allBuildingNumbers = getAllDistinctPropertyBuildingNumbers(units)

    const handleOpened = (cadastreId: number) => {
        if (opened.includes(cadastreId)) {
            setOpened(opened.filter((o) => o !== cadastreId))
        } else {
            setOpened([...opened, cadastreId])
        }
    }

    return (
        <div>
            {isSectioned && isMainProperty(cadastreNumber) && (
                <div css={styles.sectionTitle}>{t('MAIN_PROPERTY', 'Grunneiendom')}</div>
            )}

            {!isSectioned && (
                <InePropertyProduct
                    address={mainProperty}
                    cadastreNumber={cadastreNumber}
                    owners={mainOwners}
                    propertyBuildingNumbers={allBuildingNumbers}
                />
            )}
            <div tabIndex={0}>
                {isSectioned && isMainProperty(cadastreNumber) && (
                    <>
                        <PropertyHeader isMain={true} />
                        <div css={styles.propertyLine} onClick={() => handleOpened(0)}>
                            <div css={[styles.column1, styles.gridItem]}>{formatCadastreNumber(cadastreNumber)}</div>
                            <div css={[styles.column2, styles.gridItem]} />
                            <div css={[styles.column3, styles.gridItem]} />
                            <div css={[styles.column4, styles.gridItem]} />
                            <div css={[styles.column5, styles.gridItem]} />
                            <div css={[styles.column6, styles.gridItem]} />
                            <div css={[styles.column7, styles.gridItem]}>
                                <KeyboardArrowDown />
                            </div>
                        </div>
                        <br />
                        {opened.includes(0) && (
                            <div css={styles.expandedContent}>
                                <InePropertyProduct
                                    address={mainProperty}
                                    cadastreNumber={cadastreNumber}
                                    owners={mainOwners}
                                    propertyBuildingNumbers={allBuildingNumbers}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>

            {isSectioned && (
                <UnitsTable
                    units={units.filter((u) => !isMainProperty(u.cadastreNumber))}
                    title={t('SECTIONS', 'Seksjoner ({{huCount}})', {
                        huCount: units.filter((u) => !isMainProperty(u.cadastreNumber)).length,
                    })}
                />
            )}
            {showDetachedHousingUnits(units) && (
                <UnitsTable
                    units={getDetachedHousingUnits(units)}
                    title={t('DETACHED_HUS', 'Bruksenheter som ikke er tilknyttet seksjon i matrikkel ({{huCount}})', {
                        huCount: getDetachedHousingUnits(units).length,
                    })}
                />
            )}
            {!showDetachedHousingUnits(units) && getDetachedHousingUnits(units).length > 0 && (
                <UnitsTable
                    units={getDetachedHousingUnits(units)}
                    title={t('HOUSING_UNITS', 'Bruksenheter ({{huCount}})', {
                        huCount: getDetachedHousingUnits(units).length,
                    })}
                />
            )}
        </div>
    )
}
