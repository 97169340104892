/** @jsxImportSource @emotion/react */
import { getStandardRightActions, TopBar } from '../topbar/top-bar'
import { Grid } from '@mui/material'
import { BottomPageNavigation } from '../bottom-navigation/bottom-navigation'
import { KotiContainer } from '../general/layout/koti-container'
import { useTranslation } from 'react-i18next'
import { TopPageTabs } from '../general/tabs/top-page-tabs'
import React, { useState } from 'react'
import { CubitAdminCompaniesView } from './cubit-admin-companies.view'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ConfirmDialog } from '../general/dialogs/confirm-dialog'
import { KotiButtonSecondary } from '../general/buttons/koti-button-secondary'
import { companiesActions, selectSelectedCompanies } from '../companies/company.slice'
import { useDeleteCompanyMutation } from '../companies/companies.service'
import { AdminProductsPage } from '../products/admin-products.view'
import { useCreateInvoiceMutation } from '../invoicing/invoicing.service'
import { SendContractDialog } from './dialogs/send-contract-dialog'
import { RealtorReportView } from '../ine-admin-page/tabs/realtor-report.view'
import { CubitAdminNotificationsView } from './cubit-admin-notifications.view'

export enum AdminTabs {
    Invoice = 'invoice',
    Companies = 'companies',
    Products = 'products',
    AllOrders = 'allOrders',
    Notifications = 'notifications',
}
export const CubitAdminPage: React.FC = () => {
    const [tab, setTab] = useState(AdminTabs.Companies)
    const { t } = useTranslation()
    const tabs = [
        // { value: AdminTabs.Invoice, label: t('TEXT_INVOICE', 'Faktura') },
        { value: AdminTabs.Companies, label: t('TEXT_REALTOR_COMPANY', 'Meglerkontor') },
        { value: AdminTabs.Products, label: t('TEXT_PRODUCTS', 'Produkter') },
        { value: AdminTabs.AllOrders, label: t('TEXT_ALL_ORDERS', 'All orders') },
        { value: AdminTabs.Notifications, label: t('TEXT_NOTIFICATIONS_TAB_LABEL', 'Statusmeldinger') },
    ]
    const selectedCompanies = useAppSelector(selectSelectedCompanies)
    const companyIds = selectedCompanies.map((x) => x.id)
    const dispatch = useAppDispatch()
    const [handleDelete] = useDeleteCompanyMutation()
    const [createInvoice] = useCreateInvoiceMutation()
    const handleDeselectCompanies = () => {
        dispatch(companiesActions.setSelectedItems([]))
    }
    let secondaryActions
    if (selectedCompanies && selectedCompanies.length > 0) {
        secondaryActions = [
            <ConfirmDialog
                key={'delete_companies'}
                title={t('DELETE_COMPANIES_HEADER', 'Delete companies')}
                content={t('DELETE_COMPANIES_CONTENT', 'Are you sure you want to delete selected companies')}
                onConfirm={() => {
                    handleDelete(companyIds).then(handleDeselectCompanies)
                }}
            >
                <KotiButtonSecondary
                    text={t('TEXT_DELETE_COMPANY', 'Slett Meglerkontor', { count: selectedCompanies.length })}
                />
            </ConfirmDialog>,
            <ConfirmDialog
                key={'create-invoice'}
                title={t('CREATE_INVOICES_HEADER', 'Create invoices')}
                content={t('CREATE_INVOICES_CONTENT', 'This is important financial decision. Are you sure?')}
                onConfirm={() => {
                    createInvoice(companyIds).then(handleDeselectCompanies)
                }}
            >
                <KotiButtonSecondary
                    text={t('TEXT_CREATE_INVOICES', 'Create invoice', { count: selectedCompanies.length })}
                />
            </ConfirmDialog>,
        ]
        if (companyIds && companyIds.length === 1) {
            secondaryActions.push(
                <SendContractDialog onDone={() => handleDeselectCompanies()} companyId={companyIds[0]} />,
            )
        }
    }

    return (
        <div>
            <TopBar
                rightActions={getStandardRightActions()}
                secondary={{
                    rightActions: secondaryActions,
                    secondaryText: t('TEXT_COMPANY_SELECTED', '{{count}} meglerkontor valgt', {
                        count: selectedCompanies.length,
                    }),
                    onCancel: () => dispatch(companiesActions.setSelectedItems([])),
                }}
            />
            <KotiContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TopPageTabs tabs={tabs} tab={tab} setTab={setTab} title={t('TEXT_ADMIN', 'Admin')} />
                    </Grid>
                    <Grid item xs={12}>
                        {/*{tab === AdminTabs.Invoice && <CubitAdminInvoiceView />}*/}
                        {tab === AdminTabs.Companies && <CubitAdminCompaniesView />}
                        {tab === AdminTabs.Products && <AdminProductsPage />}
                        {tab === AdminTabs.AllOrders && <RealtorReportView />}
                        {tab === AdminTabs.Notifications && <CubitAdminNotificationsView />}
                    </Grid>
                </Grid>
            </KotiContainer>
            <BottomPageNavigation />
        </div>
    )
}
