/** @jsxImportSource @emotion/react */
import { Paper } from '@mui/material'
import { TFunction } from 'i18next'
import React from 'react'
import { Company } from './models/company'
import { TableStructure } from '../general/table/table-structure.type'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { companiesActions, selectSelectedCompanies } from './company.slice'
import { KotiTable } from '../general/table/koti-table'
import { useGetDepartmentsQuery } from './companies.service'
import { formatOrgNumber } from './companies-helper'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useNavigate } from 'react-router-dom'
import { getCompanyRoute } from '../../utils/routes'
import { formatDateTime } from '../../utils/date-utils'
import { useAuthContext } from '../auth/auth.slice'

const getProductTableStructure = (t: TFunction): TableStructure<Company>[] => [
    {
        label: t('TEXT_NAME', 'Navn'),
        columnName: 'name',
        sortFunction: (object) => object.name,
        value: (object) => object.name,
    },
    {
        label: t('TEXT_ORG_NR', 'Org.nummer'),
        columnName: 'organizationNr',
        sortFunction: (object) => object.organizationNr,
        value: (object) => formatOrgNumber(object.organizationNr),
        mobileDisplay: 'none',
    },
    {
        label: t('TEXT_ADDRESS', 'Address'),
        columnName: 'address',
        sortFunction: (object) => object.address.addressText,
        value: (object) => `${object.address.addressText} ${object.address.postalCode}`,
        mobileDisplay: 'none',
    },
    {
        label: t('TEXT_CREATED', 'Created'),
        columnName: 'created',
        sortFunction: (object) => object.created,
        value: (object) => formatDateTime(object.created),
        mobileDisplay: 'none',
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (property) => property.name,
        value: () => <KeyboardArrowRightIcon />,
    },
]
type Props = {
    companyId?: string
}
export const DepartmentsTable: React.FC<Props> = ({ companyId }) => {
    const navigate = useNavigate()
    const selectedItems = useAppSelector(selectSelectedCompanies)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const context = useAuthContext()
    const handleOnSelectChange = (selectedItems: Company[]) => {
        dispatch(companiesActions.setSelectedItems(selectedItems))
    }
    const cId = companyId ? companyId : context ? context.entityId : ''
    // const [create, { isLoading, error, isError }] = useCreateDepartmentMutation()
    const { data: departments } = useGetDepartmentsQuery(cId)
    const tableStructure = getProductTableStructure(t)

    return (
        <Paper elevation={0} square={true}>
            <KotiTable
                sortable={false}
                selectable={false}
                pagination={false}
                onRowClick={(item: Company) => {
                    navigate(getCompanyRoute(item))
                }}
                selectedItems={selectedItems}
                tableStructure={tableStructure}
                rowsPerPage={0}
                page={0}
                rows={departments}
                onSelectChange={handleOnSelectChange}
                total={0}
            />
        </Paper>
    )
}
