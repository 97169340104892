import { BaseEntity } from '../general/models/base-entity'
import { ProductPlant } from './models/plants'

export enum CubitPriceType {
    Flat = 'flat',
    Percentage = 'percentage',
}

export const ProductTypes = {
    Taxes: 'Taxes',
    Fire: 'Fire',
    Electricity: 'Electricity',
} as const
export type ProductType = (typeof ProductTypes)[keyof typeof ProductTypes]

export type Product = {
    name: string
    description: string
    vendorId: string
    vendorName: string
    tenantId: string
    tenantName: string
    price: number
    basePrice: number
    cubitPrice: number
    vat: number
    cubitPriceType: CubitPriceType
    type: ProductType
    disclaimer?: string
} & BaseEntity

export type AvailableProduct = {
    name: string
    description: string
    vendorId: string
    vendorName: string
    tenantId: string
    tenantName: string
    price: number
    attributes: ProductAttribute[]
    electricityAttributes: ProductPlant[] | null
    type: ProductType
    disclaimer?: string
} & BaseEntity

export type ProductAttribute = {
    matrikeklNr: string
    invoiceReceivers: InvoiceReceiver[]
}

export type InvoiceReceiver = {
    name: string
    partNumber: number
    agreementNumber: number
}

export type BaseProduct = Omit<Product, keyof BaseEntity>

export const getTotalProductPrice = (product: Product): number => {
    if (product.cubitPriceType === CubitPriceType.Flat) {
        return calculateFlatPrice(product)
    }
    if (product.cubitPriceType === CubitPriceType.Percentage) {
        return calculatePercentagePrice(product)
    }
    return product.basePrice || 0
}

const calculateFlatPrice = ({ basePrice, cubitPrice, vat }: Product) => {
    const productPrice = basePrice + cubitPrice
    const actualVat = vat || 0
    return actualVat === 0 ? productPrice : Math.round(productPrice * (actualVat / 100 + 1) * 100) / 100
}

const calculatePercentagePrice = ({ basePrice, cubitPrice, vat }: Product) => {
    const productPrice = basePrice * (1 + cubitPrice / 100)
    const actualVat = vat || 0
    return actualVat === 0 ? productPrice : Math.round(productPrice * (actualVat / 100 + 1) * 100) / 100
}
