import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { CompanyConfig } from 'components/companies/models/company'
import { useForm } from 'react-hook-form'
import StyledContainedButton from 'components/styled/styled-contained-button'
import { ControlledMuiCheckbox } from 'components/general/forms/rhf/ControlledMuiCheckbox'
import { ControlledMuiTextField } from 'components/general/forms/rhf/ControlledMuiTextField'
import { HUMAN_READABLE_COMPANY_CONFIG_KEYS } from 'constants/companyConfig'
import { useEditCompanyConfigMutation } from 'components/companies/companies.service'
import { KeysOfUnion } from 'types/util'

type EditCompanyConfigDialogProps = {
    onClose: () => void
    config: CompanyConfig
    configSectionKey: keyof CompanyConfig
    companyId: string
}

export const EditCompanyConfigDialog: React.FC<EditCompanyConfigDialogProps> = ({
    onClose,
    config,
    configSectionKey,
    companyId,
}) => {
    const { t } = useTranslation()
    const [edit, { isLoading }] = useEditCompanyConfigMutation()

    const section = config[configSectionKey]
    const formId = `company-config-${configSectionKey}`

    const { handleSubmit, control } = useForm<CompanyConfig[keyof CompanyConfig]>({
        defaultValues: section,
        shouldFocusError: true,
        mode: 'onSubmit',
        shouldUnregister: true,
    })

    const onSubmit = async (data: CompanyConfig[keyof CompanyConfig]) => {
        const newConfig: CompanyConfig = {
            ...config,
            [configSectionKey]: data,
        }
        await edit({ config: newConfig, id: companyId }).unwrap()
        onClose()
    }

    const sectionKeys = Object.keys(section) as unknown as KeysOfUnion<typeof section>[]

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="xl">
            <CloseWrapper>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </CloseWrapper>

            <DialogTitle>{t('COMPANY_CONFIG_EDIT_DIALOG_TITLE', 'Edit section')}</DialogTitle>

            <DialogContent sx={{ mt: 2 }}>
                <ControlledForm id={formId} onSubmit={handleSubmit(onSubmit)}>
                    {sectionKeys.map((key) => {
                        const value = key in section ? (section as Record<string, unknown>)[key] : undefined

                        if (value === undefined) return null
                        if (typeof value === 'boolean')
                            return (
                                <ControlledMuiCheckbox
                                    control={control}
                                    name={key}
                                    key={key}
                                    label={t(HUMAN_READABLE_COMPANY_CONFIG_KEYS[key])}
                                />
                            )
                        if (typeof value === 'string')
                            return <ControlledMuiTextField control={control} name={key} type="string" key={key} />
                        if (typeof value === 'number')
                            return <ControlledMuiTextField control={control} name={key} type="number" key={key} />

                        return null
                    })}
                </ControlledForm>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>
                    {t('TEXT_CANCEL', 'AVBRYT')}
                </Button>
                <StyledContainedButton
                    disabled={isLoading}
                    variant="contained"
                    color="secondary"
                    form={formId}
                    type="submit"
                >
                    {t('TEXT_SAVE', 'LAGRE')}
                </StyledContainedButton>
            </DialogActions>
        </Dialog>
    )
}

const CloseWrapper = styled('div')({
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 1,
})

const ControlledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}))
