import { Launch } from '@mui/icons-material'
import { Button, Checkbox, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { dispatchSubmit } from '../../utils/forms-utils'
import { ValidatorRequired } from '../../utils/validators/validator-required'
import { formatOrgNumber } from '../companies/companies-helper'
import { useFinishRegistrationMutation, useLazyGetCompanyBySecretKeyQuery } from '../companies/companies.service'
import { TermsDialog } from '../companies/TermsDialog'
import { CubitTextFieldAdapter } from '../general/forms/cubit-text-field-adapter'
import { KotiContainer } from '../general/layout/koti-container'
import { QueryStatusView } from '../general/query-status.view'
import { getMinimalRightActions, TopBar } from '../topbar/top-bar'
import StyledContainedButton from '../styled/styled-contained-button'
import { TermsBlock } from './terms'
import {
    CompanyInfoContainer,
    FormContainer,
    FormHeader,
    StyledForm,
    StyledTermsBtnText,
    TermsAndConditionsContainer,
} from './styles'
import { styled } from '@mui/system'

type RealtorAdminForm = {
    realtorAdminName: string
    realtorAdminEmail: string
}

const FORM_ID = 'FINISH_SIGNUP'

export const FinishCompanyAdminSignup: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const secretKey = searchParams.get('secretKey')

    const [getCompany, { data }] = useLazyGetCompanyBySecretKeyQuery()
    const [acceptInvite, { error, isError }] = useFinishRegistrationMutation()

    const [isOpen, setIsOpen] = useState(false)
    const [agree, setAgree] = useState(false)

    useEffect(() => {
        if (secretKey) {
            getCompany(secretKey)
        }
    }, [getCompany, secretKey])

    const handleOpen = () => {
        setIsOpen((prev) => !prev)
    }

    const onSubmit = async (values: RealtorAdminForm) => {
        if (!secretKey || !data) return

        await acceptInvite({
            secretKey,
            id: data.id,
            realtorAdminName: values.realtorAdminName,
            realtorAdminEmail: values.realtorAdminEmail,
        }).unwrap()

        navigate('/')
    }

    return (
        <div style={{ backgroundColor: '#fff', height: '100vh' }}>
            <TopBar rightActions={getMinimalRightActions()} />
            <KotiContainer>
                <FormContainer container>
                    <FormHeader>{t('TEXT_FINISH_SIGNUP', 'Finish sign up')}</FormHeader>

                    {data && (
                        <Grid item xs={12}>
                            <CompanyInfoContainer>
                                <TermsBlock label={t('TEXT_NAME', 'Navn')} value={data.name} />
                                <TermsBlock
                                    label={t('TEXT_ORG_NR', 'Org. nr.')}
                                    value={formatOrgNumber(data.organizationNr)}
                                />
                                <TermsBlock label={t('TEXT_ADDRESS', 'Adresse')} value={data.address.addressText} />
                                <TermsBlock
                                    label={t('TEXT_POSTAL_CODE', 'Postnummer')}
                                    value={data.address.postalCode}
                                />
                            </CompanyInfoContainer>
                        </Grid>
                    )}
                    {data ? (
                        <Form
                            initialValues={{
                                realtorAdminName: data.realtorAdminName,
                                realtorAdminEmail: data.realtorAdminEmail,
                            }}
                            onSubmit={onSubmit}
                            render={({ handleSubmit }) => (
                                <StyledForm id={FORM_ID} onSubmit={handleSubmit}>
                                    <AdminInfoBlock>
                                        {t('TEXT_INFO_ABOUT_ADMIN', 'Informasjon om faglig ansvarlig')}
                                    </AdminInfoBlock>
                                    <Field
                                        name="realtorAdminName"
                                        width="100%"
                                        component={CubitTextFieldAdapter}
                                        label={t('TEXT_NAME', 'Navn')}
                                        validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                        variant="standard"
                                    />
                                    <Field
                                        name="realtorAdminEmail"
                                        width="100%"
                                        component={CubitTextFieldAdapter}
                                        label={t('TEXT_EMAIL', 'E-postadresse')}
                                        validate={(value) => ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))}
                                        variant="standard"
                                    />
                                    <TermsAndConditionsContainer>
                                        <Checkbox
                                            onChange={() => setAgree((prev) => !prev)}
                                            checked={agree}
                                            sx={{ padding: '0' }}
                                        />
                                        <Button onClick={() => handleOpen()}>
                                            <StyledTermsBtnText>
                                                {t(
                                                    'TERMS_AND_CONDITIONS',
                                                    'Jeg aksepterer koti sine vilkår og betingelser.',
                                                )}
                                            </StyledTermsBtnText>
                                            <Launch />
                                        </Button>
                                    </TermsAndConditionsContainer>
                                    <StyledContainedButton
                                        variant={'contained'}
                                        color={'secondary'}
                                        disabled={!agree}
                                        onClick={() => dispatchSubmit(FORM_ID)}
                                        style={{ width: '50%', marginTop: '40px' }}
                                    >
                                        {t('TEXT_SEND_REQUEST', 'Send forespørsel')}
                                    </StyledContainedButton>
                                    <QueryStatusView error={error} isError={isError} />
                                </StyledForm>
                            )}
                        />
                    ) : null}
                </FormContainer>
            </KotiContainer>
            <TermsDialog
                isDialogOpened={isOpen}
                handleCloseDialog={() => setIsOpen(false)}
                handleTermsAgree={() => setAgree(true)}
            />
        </div>
    )
}

const AdminInfoBlock = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
}))
