import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from '../components/auth/auth.slice'
import productsReducer from '../components/products/product.slice'
import usersReducer from '../components/users/users.slice'
import companiesReducer from '../components/companies/company.slice'
import ordersReducer from '../components/orders/order.slice'
import cartReducer from '../components/cart/cart.slice'
import bottomNavigationReducer from '../components/bottom-navigation/bottom-navigation.slice'
import { appApi } from './api'
import { companiesApi } from './companies-api'

const persistConfig = {
    key: 'auth',
    storage,
}

const persistedAuthReducer = persistReducer(persistConfig, authReducer)

export const store = configureStore({
    reducer: {
        [appApi.reducerPath]: appApi.reducer,
        [companiesApi.reducerPath]: companiesApi.reducer,
        auth: persistedAuthReducer,
        products: productsReducer,
        users: usersReducer,
        companies: companiesReducer,
        cart: cartReducer,
        orders: ordersReducer,
        bottomNavigation: bottomNavigationReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat([appApi.middleware, companiesApi.middleware]),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
