/** @jsxImportSource @emotion/react */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    LinearProgress,
    Paper,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { css } from '@emotion/react'
import { useGetCompanyDocumentsQuery } from '../companies.service'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../utils/date-utils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Signatory } from '../../cubit-admin-page/types/signatory.type'
import React from 'react'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import { ContentCopy } from '@mui/icons-material'
import { Signature } from '../../cubit-admin-page/types/signable-document.type'

type Props = {
    companyId: string
}
const styles = {
    wrapper: css`
        padding: 16px;
    `,
    documentHeader: css`
        display: flex;
        width: 100%;
        justify-content: space-between;
    `,
}
const SignatoriesTable: React.FC<{ signatories: Signatory[]; signatures: Signature[] }> = ({
    signatories,
    signatures,
}) => {
    const { t } = useTranslation()
    return (
        <div>
            <Typography style={{ textTransform: 'uppercase' }}>
                {t('required_signatories', 'Required Signatories')}
            </Typography>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('TEXT_NAME', 'Navn')}</TableCell>
                        <TableCell>{t('TEXT_EMAIL', 'Email')}</TableCell>
                        <TableCell>{t('TEXT_NAME_CHECK', 'Name check')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {signatories.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>
                                {signatures.map((s) => s.name).includes(row.name) ? (
                                    <span style={{ color: 'darkgreen', fontWeight: 'bold' }}>
                                        {t('match', 'Match')}
                                    </span>
                                ) : (
                                    <span style={{ color: 'indianred', fontWeight: 'bold' }}>
                                        {t('no_match', 'Mismatch')}
                                    </span>
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    onClick={() => {
                                        row.href ? navigator.clipboard.writeText(row.href) : null
                                    }}
                                >
                                    <ContentCopy />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Typography style={{ textTransform: 'uppercase', margin: '32px 0 0 0' }}>
                {t('signed_by', 'Sigend By')}
            </Typography>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('TEXT_NAME', 'Name')}</TableCell>
                        <TableCell>{t('TEXT_DATE', 'Date')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {signatures.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{formatDateTime(row.signedAt)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}
export const DocumentsView: React.FC<Props> = ({ companyId }) => {
    const { data } = useGetCompanyDocumentsQuery({ companyId })
    const { t } = useTranslation()

    if (!data)
        return (
            <div>
                <LinearProgress />
            </div>
        )
    return (
        <div>
            <Paper css={styles.wrapper} elevation={0} square={true}>
                {data.length === 0 && <div>{t('NO_DOCUMENTS', 'There are no documents')}</div>}
                {data.map((document, index) => (
                    <Accordion key={document.id} defaultExpanded={index === 0}>
                        <AccordionSummary
                            aria-controls="document-content"
                            id="documentHeader"
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div css={styles.documentHeader}>
                                <Typography>
                                    {document.title} - <b>{document.status}</b>
                                </Typography>
                                <Typography style={{ fontSize: 14, marginRight: 10 }}>
                                    {formatDateTime(document.created)}
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SignatoriesTable signatories={document.requiredSignators} signatures={document.signedBy} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Paper>
        </div>
    )
}
