import { ProductPlant } from 'components/products/models/plants'
import { appApi } from '../../redux/api'
import { transformPropertyUnitsRequest } from '../../utils/transformers/propertyUnitTransform'
import {
    AddressSearchRequest,
    AddressSearchResponse,
    CadastreResponse,
    HousingUnitsRequest,
    PropertyUnitsRequest,
} from './models/address-search-response'
import { PropertyResponseItem, TransformedPropertyResponseItemResponse } from './models/property-search-response'

const searchApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        searchAddress: builder.query<AddressSearchResponse[], AddressSearchRequest>({
            query: (params) => ({
                url: `/v1/search/address`,
                method: 'GET',
                params: params,
            }),
        }),

        getHousingUnits: builder.query<CadastreResponse, HousingUnitsRequest>({
            query: (params) => ({
                url: `/v1/search/address-housing-units`,
                method: 'GET',
                params: params,
            }),
        }),

        getPropertyUnits: builder.query<TransformedPropertyResponseItemResponse, PropertyUnitsRequest>({
            query: (params) => ({
                url: `/v1/search/matrikkel-housing-units`,
                method: 'GET',
                params: params,
            }),
            transformResponse: (response: PropertyResponseItem[]) => transformPropertyUnitsRequest(response),
        }),

        getPlants: builder.query<ProductPlant[], { query: string; tenantId: string }>({
            query: (params) => ({
                url: `/v1/search/plants`,
                method: 'GET',
                params: params,
            }),
        }),
    }),
})

export const {
    useLazySearchAddressQuery,
    useSearchAddressQuery,
    useGetHousingUnitsQuery,
    useGetPropertyUnitsQuery,
    useGetPlantsQuery,
    useLazyGetPlantsQuery,
} = searchApi
