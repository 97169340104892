/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, FormControl, InputAdornment, TextField } from '@mui/material'
import { cubitFormStyles } from './cubit-form-styles'
import { useTranslation } from 'react-i18next'
import { formatOrgNumber } from '../../companies/companies-helper'
import { Search } from '@mui/icons-material'
import { css } from '@emotion/react'
import { useLazySearchCompaniesQuery } from '../../companies/companies.service'
import { Company } from '../../companies/models/company'

const styles = {
    company2ndLine: css`
        display: flex;
    `,
    orgNr: css`
        margin-right: 40px;
    `,
    selectedCompany: css`
        padding: 10px 0;
        white-space: nowrap;
        margin-bottom: 30px;
        display: inline-flex;
        align-items: center;
    `,
}
export const KotiCompanySearchAdapter: React.FC<any> = (props: any) => {
    const [searchValue, setSearchValue] = useState('')
    const [trigger, { data }] = useLazySearchCompaniesQuery()
    useEffect(() => {
        if (searchValue && searchValue.length > 1) {
            trigger({ q: searchValue })
        }
    }, [trigger, searchValue])
    const {
        label,
        valueChanged,
        input: { onChange },
        meta: { touched, error },
    } = props
    const { t } = useTranslation()
    return (
        <>
            <FormControl css={cubitFormStyles.field}>
                <Autocomplete
                    onChange={(event, value) => {
                        onChange(value)
                        if (valueChanged) {
                            valueChanged(value)
                        }
                    }}
                    id="company-search"
                    options={data ?? []}
                    autoHighlight
                    noOptionsText={t('TEXT_NO_OPTIONS', 'Ingen treff')}
                    forcePopupIcon={false}
                    getOptionLabel={(option: Company) => option.name}
                    filterOptions={(options: Company[]) => {
                        return options.filter((o) => {
                            return (
                                o.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                o.organizationNr.toString().includes(searchValue.replaceAll(' ', ''))
                            )
                        })
                    }}
                    renderOption={(props, option: Company) => (
                        <Box component="li" {...props} key={`${option.organizationNr}_${option.name}`}>
                            <div>
                                <div>{option.name}</div>
                                <div css={styles.company2ndLine}>
                                    <div css={styles.orgNr}>{formatOrgNumber(option.organizationNr.toString())}</div>
                                    {option.parentId && <div>({t('short_department', 'Avd.')})</div>}
                                </div>
                            </div>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color={'primary'}
                            variant={'standard'}
                            label={label}
                            onChange={(event) => setSearchValue(event.target.value)}
                            error={!!(touched && error)}
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search color="primary" />
                                    </InputAdornment>
                                ),
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </FormControl>
        </>
    )
}
