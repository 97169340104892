import { appApi } from '../../redux/api'
import { PaginatedResults } from '../general/models/paginated-results'
import { PaginationParams } from '../general/models/pagination-params'
import { IneData } from './models/ine-data.type'
import { Tenant } from './models/tenant'
import { Vendor } from './models/vendor'
import { AvailableProduct, CubitPriceType, Product, ProductType } from './product'
import { ProductFilters } from './product.slice'

export type CadastreProductsRequest = {
    addressId: number
    cadastreId: number
}

export type CadastreNumberProductsRequest = {
    cadastreNumber: string
    cadastreId?: number
}

export type AvailableProductsResponse = {
    ineData: IneData
    products: AvailableProduct[]
    ineFetchError: boolean
}

export type ProductCreateUpdateRequest = {
    name: string
    description: string
    vendorId: string
    tenantId?: string
    basePrice: number
    cubitPrice: number
    cubitPriceType: CubitPriceType
    type: ProductType
    disclaimer?: string
}

const productsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query<PaginatedResults<Product>, PaginationParams<Product, ProductFilters>>({
            query: (params) => ({
                url: '/v1/products',
                method: 'GET',
                params: params,
            }),
            providesTags: ['Products'],
        }),
        getProductsByCadastreId: builder.query<AvailableProductsResponse, CadastreProductsRequest>({
            query: (params) => ({
                url: '/v1/products/available',
                method: 'GET',
                params: params,
            }),
            providesTags: [],
        }),
        getProductsByCadastreNumber: builder.query<AvailableProductsResponse, CadastreNumberProductsRequest>({
            query: (params) => ({
                url: '/v1/products/available',
                method: 'GET',
                params: params,
            }),
            providesTags: [],
        }),
        createProduct: builder.mutation<Product, ProductCreateUpdateRequest>({
            query: (params) => ({
                url: '/v1/products',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Products', 'History'],
        }),
        updateProduct: builder.mutation<Product, { update: ProductCreateUpdateRequest; id: string }>({
            query: (params) => ({
                url: `/v1/products/${params.id}`,
                method: 'PUT',
                body: params.update,
            }),
            invalidatesTags: ['Products', 'History'],
        }),
        deleteProduct: builder.mutation<void, string[]>({
            query: (params) => ({
                url: `/v1/products`,
                method: 'DELETE',
                body: { ids: params },
            }),
            invalidatesTags: ['Products', 'History'],
        }),
        // Should this be here or should this be in entirely different service?
        getTenants: builder.query<Tenant[], void>({
            query: () => ({
                url: '/v1/tenantData/active',
                method: 'GET',
            }),
        }),
        getVendors: builder.query<Vendor[], void>({
            query: () => ({
                url: '/v1/products/vendors',
                method: 'GET',
            }),
        }),
    }),
})

export const {
    useGetProductsQuery,
    useLazyGetProductsQuery,
    useGetProductsByCadastreIdQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useGetTenantsQuery,
    useGetVendorsQuery,
    useGetProductsByCadastreNumberQuery,
    useLazyGetProductsByCadastreNumberQuery,
} = productsApi
