import React from 'react'
import { Box, Grid } from '@mui/material'
import { PropertyOverview } from 'components/property/shared/PropertyOverview'
import {
    flattenAndMapHouseUnits,
    generatePropertyAddressObject,
    getAllOwners,
    getMainProperty,
    getMainPropertyCadastreNumber,
} from 'utils/cadastre-utils'
import { CartProperty, PropertyResponseItem, PropertyUnit } from 'components/search/models/property-search-response'
import { PropertyProductsList } from 'components/property/products/PropertyProductsList'
import { useTranslation } from 'react-i18next'
import { GeneralPropertyUnitTable } from 'components/property/shared/GeneralPropertyUnitTable'

type MainPropertyViewProps = {
    units: PropertyResponseItem[]
}

// this view is reused for these property types:
// main property, leased property
// as of now they all behave the same
export const MainPropertyView: React.FC<MainPropertyViewProps> = ({ units }) => {
    const { t } = useTranslation()

    const mainProperty = getMainProperty(units)
    const mainPropertyAddress = generatePropertyAddressObject(units)
    const sanitizedMainPropertyCadastreNr = getMainPropertyCadastreNumber(mainProperty?.cadastreNumber || null)
    const owners = getAllOwners(units)
    const allHouseUnits = flattenAndMapHouseUnits(units)

    const property: CartProperty = {
        address: mainPropertyAddress,
        cadastreId: 0,
        cadastreNumber: sanitizedMainPropertyCadastreNr,
        owners: owners,
        units: allHouseUnits.map((propUnit) => propUnit.housingUnits).flat() as PropertyUnit[],
    }

    return (
        <>
            <Grid item xs={12}>
                <PropertyOverview
                    data={units}
                    isPropertySectioned={false}
                    sanitizedMainPropertyCadastreNr={sanitizedMainPropertyCadastreNr}
                    mainProperty={mainProperty}
                    allOwners={owners}
                />
                <div>
                    <Box mt={2}>
                        <PropertyProductsList property={property} />
                    </Box>
                    <Box mt={2}>
                        <GeneralPropertyUnitTable
                            units={allHouseUnits}
                            title={t('HOUSING_UNITS', 'Bruksenheter ({{huCount}})', {
                                huCount: allHouseUnits.length,
                            })}
                        />
                    </Box>
                </div>
            </Grid>
            <Grid item xs={12}></Grid>
        </>
    )
}
